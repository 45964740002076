import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { connect as connectFela } from "react-fela"

import Container from "components/container"
import Layout from "components/layout"
import Poster from "components/Poster"
import colors from "theme/colors"
import ResourcePageHeader from "components/ResourcePageHeader"

const isSecondPosterOnTheRow = (poster, index, posters) => !poster.fullwidth && (index > 0 && !posters[index-1]?.fullwidth)

const PosterPage = ({ path, pageContext, data, rules, styles }) => {
  const {
    title,
    meta,
    slug,
    intro,
    posters,
  } = data.sanityPostersPage
return (
  <Layout
    pageColor={colors.colorPattern50}
    linkColor={colors.colorPattern200}
    title={title}
    slug={{ current: path }}
    meta={meta || {}}
    settings={data.settings}
    jobs={data.jobs}
    country={pageContext.country}
  >
      <ResourcePageHeader
        title={title}
        intro={intro}
        breadcrumbLeft={"Resources"}
        breadcrumbRight={"POSTERS"}
        pageColor={colors.colorPattern50} />

      <Container flex withContentArea5 extend={{styles: rules.postersContainer}}>
        {posters && posters.map((poster, index) =>
          <Poster
            key={'poster' + index}
            fullWidth={poster.fullwidth}
            alt={isSecondPosterOnTheRow(poster, index, posters)}
            image={poster.image}
            title={poster.title}
            poster={poster.poster}
            excerpt={poster.excerpt}
            link={poster.link}
          />
        )}
      </Container>
    </Layout>
  )
}

const styles = () => ({
  postersContainer: {
    zIndex: 2,
    justifyContent: 'space-between',
  },
})

PosterPage.defaultProps = {
  rules: {},
  styles: {},
}

PosterPage.propTypes = {
  data: PropTypes.object.isRequired,
  rules: PropTypes.object,
  styles: PropTypes.object,
}

export default connectFela(styles)(PosterPage)

export const query = graphql`
query($country: String!) {
    settings: sanitySettings(countrySet: { eq: $country }) {
        ...Settings
    }

    jobs: allSanityJob(filter: { countrySet: { eq: $country } }) {
        edges {
            node {
                ...Jobs
            }
        }
    }

    sanityPostersPage {
      countrySet
      title
      meta {
        title
        description
        other {
          property
          content
        }
      }
      slug {
        current
      }
      intro
      posters {
        title
        excerpt
        fullwidth
        poster {
          asset {
            url
            originalFilename
          }
        }
        image {
          asset {
            gatsbyImageData(layout: CONSTRAINED, width:  625)
          }
        }
        link {
          ...sanityLinkFrag
        }
      }
    }
  }
`
