// POTENTIALLY OBSOLTE
import React, { useState } from "react"
import PropTypes from "prop-types"
import { connect as connectFela } from "react-fela"
import { GatsbyImage as Img } from 'gatsby-plugin-image'
import { useTransition, animated } from 'react-spring'

import DownloadButton from "components/DownloadButton"
import H4 from "components/typography/H4"
import Link from "components/Link"
import P from "components/typography/P"
import UnderlinedTitle from "components/UnderlinedTitle"
import calc from "utilities/calc"
import colors from "theme/colors"
import fluidValues from "utilities/fluidValues"

const Poster = ({ rules, styles, image, title, poster, excerpt, link, thankYouMessage }) => {
  const [downloaded, setDownloaded] = useState(false)
  const transitions = useTransition(downloaded, {
    from:  { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0, position: 'absolute' },
  })
  const download = () => {
    setDownloaded(true)
  }

  return (
    <div className={styles.poster}>
      {transitions((props, item) => {
        return (item ? <animated.div style={props}>

          <div className={styles.thankYouContainer}>
            <UnderlinedTitle color={"#FFFFFF"} extend={{styles: rules.thankYouTitle}}>{"Thank\nyou..."}</UnderlinedTitle>
            <P version={"medium"} extend={{styles: rules.thankYouMessage}}>
              ...For downloading this poster and sharing our passion for meaningful and beautiful graphics displaying ideas in symbolism and simplicity
            </P>
          </div>

        </animated.div>: <animated.div style={props} className={styles.menuAnim}>
          <Img className={styles.image} image={image.asset.gatsbyImageData} alt={""} />
        </animated.div>)
      })}

      <div className={styles.posterTitleContainer}>
        <H4 extend={{styles: rules.posterTitle}}>{title}</H4>
        <DownloadButton
          onClick={download}
          download={poster.asset.originalFilename}
          extend={{styles: rules.downloadButton}}
          to={poster.asset.url + "?filename=" + poster.asset.originalFilename.replace(/ /g, '_')} />
      </div>

      <P version={"info"} extend={{styles: rules.excerpt}}>{excerpt}</P>
      {link && <Link bgColor={colors.colorPattern200} color={colors.colorConsole500} to={link}>{link.label}</Link>}
    </div>
  )
}

const styles = (props) => ({
  poster: {
    ...fluidValues({marginBottom: [60, 120]}),
    display: 'flex',
    flex: '0 1 100%',
    flexDirection: 'column',
    width: '100%',

    tabletPortraitAndUp: {
      flex: '0 1 48.6%',

      extend: [
        {
          condition: props.fullWidth,
          style: {
            flex: '0 1 100%',
            marginRight: calc(40, 111, 600, 1440),
            marginLeft: calc(40, 111, 600, 1440),
          }
        },
        {
          condition: props.alt,
          style: {
            marginTop: calc(164, 200, 600, 1440),
          }
        },
      ]
    }
  },
  image: {
    ...fluidValues({marginBottom: [24, 32]}),
    border: `1px solid ${colors.colorCanvas900}`,
    boxShadow: '0px 20px 0px -10px #000'
  },
  posterTitleContainer: {
    display: 'flex',
    marginBottom: 12,
    alignItems: 'center',
  },
  posterTitle: {
    marginTop: 0,
    marginBottom: 0,
  },
  downloadButton: {
    marginLeft: 'auto',
  },
  excerpt: {
    maxWidth: 420,
    ...fluidValues({marginBottom: [25, 32]}),
  },
  thankYouContainer: {
    backgroundColor: colors.colorConsole500,
    ...fluidValues({
      paddingTop: [60, 120],
      paddingRight: [20, 80],
      paddingLeft: [20, 80],
      paddingBottom: [80, 165],
      marginBottom: [24, 32]
    }),
  },
  thankYouMessage: {
    color: '#FFFFFF',
  },
  thankYouTitle: {
    color: '#FFFFFF',
  },
})

Poster.defaultProps = {
  excerpt: '',
  image: null,
  link: {},
  poster: {},
  rules: {},
  styles: {},
  thankYouMessage: '',
  title: '',
}

Poster.propTypes = {
  excerpt: PropTypes.string,
  image: PropTypes.object,
  link: PropTypes.object,
  poster: PropTypes.object,
  rules: PropTypes.object,
  styles: PropTypes.object,
  thankYouMessage: PropTypes.string,
  title: PropTypes.string,
}

export default connectFela(styles)(Poster)
